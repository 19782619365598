.container {
  padding: 0 20px;
  color: #333;
}

.card-result {
  display: flex;
  flex-wrap: wrap;
}

.card-result-item {
  margin: 10px;
}

.content {
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.excerpt {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
