.checkout-container {
	padding: 20px;
}

.checkout-navbar {
	text-align: center;
	padding: 20px;
	border-bottom: 1px solid #dddddd;
}

.checkout-navbar img {
	width: 200px;
}

.checkout-content {
	display: grid;
	padding: 20px 0 5px;
	grid-template-columns: 2fr 1fr;
}

.checkout-content .info {
	border-right: 1px solid #dddddd;
	padding: 10px;
}

.checkout-content .info .card {
	display: grid;
	box-sizing: content-box;
	padding: 20px 5px;
	border-bottom: 1px solid #dddddd;
	grid-template-columns: 0.5fr 1fr;
}

.checkout-content .info .card:last-child {
	border-bottom: 0px;
}

.checkout-content .info .card .content {
	padding: 5px;
	cursor: inherit;
}

.info .card h2 {
	margin: 0;
	font-size: 1.1rem;
	font-weight: 600;
}

.info .card .content h2 {
	margin: 0;
	font-size: 1rem;
	font-weight: 500;
}

.address-input {
	margin-top: 10px;
	display: grid;
	grid-template-columns: auto;
	grid-gap: 10px;
}

.address {
	font-weight: 600;
	font-size: 1rem;
	margin-top: 10px;
}

.cart-price {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 15px;
}

.cart-price h2 {
	font-weight: 600 !important;
}

.summary {
	padding: 5px 20px;
}

.user-info {
	padding: 15px 0;
}

.user-info .grey {
	color: grey;
	font-weight: 600;
	font-size: 0.8rem;
}

.user-info .avatar {
	display: flex;
	align-items: center;
}

.user-info .avatar h3 {
	margin: 0;
	font-weight: 600;
}

.user-info .avatar .user-name {
	margin-left: 15px;
}

.summary .payment-info {
	display: grid;
	grid-gap: 8px;
	padding: 20px 0;
}

.summary .payment-info div,
.summary .total-payment {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.summary .payment-info div h2 {
	color: #8c8c8c;
	margin: 0;
	font-size: 0.95rem;
	font-weight: 500;
}

.summary .total-payment {
	padding-top: 15px;
	border-top: 1px solid #dddddd;
}

.summary .total-payment h2 {
	color: #262626;
	margin: 0;
	font-size: 1rem;
	font-weight: 600;
}

.autocomplete-dropdown {
	width: 100%;
	overflow: hidden;
}

.autocomplete-dropdown-container > div{
	border: 1px solid #eee;
	border-bottom: 0;
	padding: 5px 10px;
	font-size: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.autocomplete-dropdown-container > div:first-child{
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.autocomplete-dropdown-container > div:last-child{
	border: 1px solid #eee;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

@media (max-width: 1024px) {
	.checkout-content {
		grid-template-columns: 1fr 1fr;
	}
}

@media (max-width: 768px) {
	.checkout-content {
		grid-template-columns: auto;
    }
    
    .checkout-content .summary {
        grid-row:1;
        padding: 10px 10px 20px;
    }

	.checkout-content .info {
		border-right: 0;
		border-top: 1px solid black;
	}
}