/*
##Device = Desktops
##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
	.list {
		height: 60px;
	}
}

/*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
	.list {
		height: 60px;
	}
}

@media (min-width: 768px) and (max-width: 1170px) {
	.home-page-search ::-webkit-input-placeholder {
		z-index: -1;
		color: #d8d8d8;
	}

	.home-page-search :-ms-input-placeholder {
		z-index: -1;
	}

	.home-page-search ::placeholder {
		z-index: -1;
	}
}

/*
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
	.datasearch .searchbox {
		min-width: 200px;
	}

	.hide-md {
		display: none !important;
	}

	.mainBar {
		width: 100% !important;
	}
}

/*
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
	.datasearch .searchbox {
		min-width: auto;
		width: 100%;
	}

	.searchContainer {
		top: 95px;
	}

	.right-content {
		margin-top: 190px;
	}

	.collapse-button {
		margin-top: 90px !important;
	}

	.navbar {
		height: auto;
		padding: 20px;
		align-items: center;
	}

	.mainBar {
		width: 100% !important;
	}

	.tabs-container {
		margin-top: 5px;
	}

	.log-items-wrapper {
		width: 90%;
	}
}

/*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 767px) {
	.right-menu {
		width: 100%;
	}

	.bom-container {
		margin-top: 0;
	}

	.ant-popover {
		width: auto;
	}

	.upload-icon {
		right: 75px;
	}

	.voice-search {
		right: 60px;
		top: -2px;
	}

	.voice-search.active {
		right: 44px;
	}

	.sidebar-wrapper .admin-btn {
		width: 100%;
	}

	.sidebar-wrapper .admin-btn button {
		width: 100%;
	}

	.searchContainer .ant-select-selection {
		border-right: 1px solid #d9d9d9;
		border-radius: 3px;
	}

	.show-sm {
		visibility: visible;
		opacity: 1;
		display: block;
	}

	.cart-info .ant-descriptions-row {
		display: inline-block;
	}

	.hide-sm {
		display: none !important;
	}

	.mainBar {
		width: 100% !important;
	}

	.display {
		margin-top: auto !important;
	}

	.navbar {
		/* width: auto;
		height: auto;
		padding: 20px;
		align-items: flex-start; */
	}

	.logo {
		margin-left: 0;
	}

	.datasearch {
		margin-bottom: 10px;
		flex-grow: 1;
	}

	.leftSidebar {
		position: fixed;
		z-index: 100;
		width: 100% !important;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100vh !important;
		margin-top: 0 !important;
	}

	.navbar {
		position: relative;
		/* flex-direction: column; */
	}

	.navbar > * {
		/* padding-top: 10px; */
	}

	.filter-floating-icon {
		position: fixed !important;
		display: inline-block !important;
		bottom: 10px !important;
		left: 20px;
		z-index: 101;
	}

	.selected-filters {
		display: inline;
	}

	.datasearch .searchbox {
		min-width: auto;
		width: 100%;
		padding-right: 15px;
	}

	.sidebar-wrapper {
		width: auto;
	}

	.home-search-container {
		width: 90%;
	}

	.searchContainer {
		left: 0;
		width: 100% !important;
		flex-direction: column;
	}

	.searchContainer > div {
		width: 100%;
	}

	.datasearch > div > div > div {
		z-index: 0;
	}

	.searchContainer .content-icons {
		flex-grow: 1;
	}

	.searchContainer > div:first-child {
		margin-bottom: 10px;
	}

	.searchContainer .search-link-icon {
		display: block;
	}

	.search-links {
		display: none !important;
	}

	.map-filter {
		left: 0;
		width: 100% !important;
		top: 142px;
		margin-top: 10px;
		display: none;
	}
	.cart-container {
		top: 150px;
	}
	.map-searchbox {
		padding-bottom: 2px;
	}
	.reactive-map {
		top: 40px;
	}

	.homeContent {
		height: auto;
		margin-top: 100px;
		margin-left: 5px;
	}

	.navbar--home {
		flex-direction: row;
	}

	.sm-phone {
		display: block;
	}

	.title-wrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.list > header {
		position: relative;
	}

	.list > header > button {
		position: fixed !important;
		bottom: 10px !important;
		top: unset !important;
		z-index: 101;
	}

	.detail-item {
		font-size: 13px;
	}

	.detail-header {
		font-size: 18px !important;
	}

	.right-content {
		margin-top: 0px !important;
	}

	.tabs-container {
		margin-top: 0 !important;
	}

	.render-map {
		height: calc(100vh - 230px);
	}

	.map-container {
		height: calc(100vh - 145px);
		margin-top: 54px !important;
		max-height: calc(100vh - 120px);
	}

	.results {
		height: auto;
	}

	.collapse-button {
		display: none;
	}

	.input-close-icon {
		right: 55px !important;
		z-index: 1;
	}

	.circularProgress {
		left: 0px !important;
	}

	.log-items-wrapper {
		margin-left: 10px;
		margin-right: 10px;
	}
}

@media (max-width: 348px) {
	.right-content.map-container {
		margin-top: 53px !important;
	}

	.right-content {
		margin-top: 38px !important;
	}
}

@media (max-width: 425px) {
	.bom-footer {
		flex-direction: column;
	}

	.footer-button-margin {
		margin-bottom: 10px;
	}

	.searchContainer .shopping-cart {
		flex-grow: 1;
	}

	.log-items-wrapper {
		width: auto;
	}

	.searchContainer .content-icons {
		border-right: 0;
	}

	.popover-map {
		width: 100%;
	}
}

@media (max-width: 620px) {
	.leftSidebar.leftSidebarClose,
	.facets-sidebar-wrapper {
		width: 0px !important;
	}

	.layout {
		margin-top: 160px;
	}

	.data-container {
		padding: 0;
	}

	.result-data .container {
		padding: 0;
	}

	.card-result-item {
		max-width: 130px;
		margin: 10px 5px !important;
	}

	.button-container {
		flex-direction: column;
	}

	.order-footer {
		margin-top: 10px;
	}

	.view-button {
		width: 145px;
	}
}

@media (max-width: 560px) {
	.right-content {
		margin-top: 0px !important;
	}
}
