.bom-container {
	padding: 20px;
	width: 100%;
	margin-top: 80px;
}

tr.drop-over-kit-item td {
	background: #ffd6e7;
}

tr.drop-over-item td {
	background: #efdbff;
}
tr.drop-over-kit td {
	background: #d6e4ff;
}

tr:hover td {
	background: white !important;
}

td:hover td {
	background: white !important;
}

.delete-cart-item {
	transform: scale(0);
	opacity: 0;
	margin-left: 5px;
	transition: all 0.2s ease-in;
}

tr:hover .delete-cart-item {
	display: inline-block;
	transform: scale(1);
	opacity: 1;
}

.product-search {
	margin-bottom: 20px;
}

.product-search input {
	border-radius: 3px;
}

.bom-footer {
	display: flex;
	justify-content: space-between;
}
