.panel-header {
	display: flex;
	align-items: center;
}

.facet-panel-icon {
	width: 16px;
	height: 16px;
	margin-right: 10px;
}

.facet-panel-icon.small {
	width: 14px;
	height: 14px;
	margin-right: 6px;
}
