body {
	margin: 0;
	padding: 0;
	font-family: 'Open Sans', sans-serif;
}

.flex-wrapper {
	width: 60%;
	margin: 50px auto;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: wrap;
}

.flex-wrapper .box {
	width: 120px;
	height: 120px;
	cursor: pointer;
	text-align: center;
	padding-top: 16px;
}

.flex-wrapper .box .icon {
	width: 60px;
	height: 60px;
}

.cart-info .ant-descriptions-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.cart-info .ant-descriptions-row > td {
	padding: 0 !important;
}

.cart-info .ant-descriptions-title {
	margin-bottom: 5px;
	font-size: 15px;
}

@media (max-width: 768px) {
	.flex-wrapper {
		width: 100%;
	}

	.flex-wrapper .box {
		margin-top: 15px;
	}

	.flex-wrapper .box p {
		text-align: center;
	}
}

@media (max-width: 500px) {
	.flex-wrapper {
		width: 70%;
	}
}

@media (max-width: 380px) {
	.flex-wrapper {
		width: 80%;
	}

	.flex-wrapper .box {
		margin-top: 5px;
	}
}

.custom-button {
	border: 0;
	outline: 0;
	padding: 10px;
	font-size: 13px;
	cursor: pointer;
}

.custom-button.link {
	background: rgba(63, 81, 181, 0.08);
	color: #3f51b5;
	border: 1px solid #3f51b5;
	margin: 2px;
	border-radius: 2px;
}

.custom-button.link.disabled {
	background: #dfdfdf;
	color: #333;
}

.ant-popover-inner-content {
	overflow-y: scroll;
}

.ant-popover {
	width: 25%;
	right: 0;
	left: auto;
}

.ant-descriptions .delete_btn {
	opacity: 0;
	margin-left: auto;
	transition: all ease 0.2s;
	transform: scale(0);
}

.ant-descriptions:hover .delete_btn {
	opacity: 1;
	transform: scale(1);
}
