.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 15px;
  letter-spacing: 0.05rem;
  justify-content: space-between;
}
.voice-search {
  position: absolute;
  right: 65px;
  top: -1px;
}

.voice-search.active {
  right: 49px;
}

.datasearch.home-datasearch > div > div > div:last-child {
  top: calc(50% - 11px);
}

.upload-icon {
  position: absolute;
  right: 80px;
}

.facets-sidebar-wrapper {
  width: 80px;
}

.sidebar-wrapper {
  width: 260px;
  transition: all ease 0.1s;
}

.sidebar-wrapper .ant-collapse {
  border-radius: 0;
}

.sidebar-wrapper .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 !important;
}

.sidebar-wrapper .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.sidebar-wrapper .ant-collapse-content > .ant-collapse-content-box h2 {
  padding: 0;
  font-size: 14px;
  padding-top: 8px;
  color: #1f1f1f;
}

.sidebar-wrapper .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-weight: 500;
  font-size: 15px;
  color: #141414;
}

.sidebar-wrapper
  .ant-collapse
  > .ant-collapse-item-active
  > .ant-collapse-header
  > div {
  color: #e62619;
}

.sidebar-wrapper .admin-btn {
  position: fixed;
  bottom: 0px;
  margin: 0;
  z-index: 2;
}

.sidebar-wrapper .ant-list-split .ant-list-item {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  color: #262626;
  justify-content: flex-start;
}

.sidebar-wrapper .ant-list-split .ant-list-item > span {
  font-size: 16px;
  margin-right: 5px;
}

.sidebar-wrapper .ant-list-split .ant-list-item:hover {
  background: #f5f5f5;
}

.sidebar-wrapper .admin-btn button {
  cursor: pointer;
  width: 260px;
  background: #e62f23;
  color: white;
  border-radius: 0;
}

.sidebar-wrapper .ant-collapse .filter {
  padding: 0 16px 16px;
}

.sidebar-wrapper .ant-collapse .filter > ul {
  max-height: 160px;
}

.logo {
  color: #fff;
  text-decoration: none;
}

.right-menu {
  margin-right: 16px;
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
}

.right-menu > * {
  margin-right: 5px;
}

.datasearch svg.search-icon {
  margin-left: 2px;
  margin-right: 8px;
}

.searchContainer {
  top: 60px; /* Navbar Height is 60px*/
  display: flex;
  right: 0;
  flex-wrap: wrap;
  transition: all ease 0.1s;
  position: fixed;
  align-items: center;
  flex-direction: row;
  padding: 10px 15px;
  width: 100%;
  z-index: 1;
  background: white;
  border: 1px solid #ccc;
}

.searchContainer .content-icons {
  display: flex;
  justify-content: space-around;
}

.searchContainer .search-link-icon {
  display: none;
}

.search-link-icon img {
  width: 16px;
  height: 16px;
}

.searchContainer .search-icons {
  display: none;
}

.searchContainer .ant-select-selection {
  border-radius: 3px 0 0 3px;
  border-right: 0;
}

.searchContainer .ant-select-lg .ant-select-selection--single {
  height: 42px;
}

.searchContainer .ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  box-shadow: none;
  border-color: #d9d9d9;
}

.searchContainer .ant-select-selection:hover {
  border-color: #d9d9d9;
}

.searchContainer .list-options .ant-select-selection {
  border-radius: 4px;
  border-right: 1px solid #d9d9d9;
  width: 120px;
}

.datasearch {
  display: flex !important;
  flex-grow: 1;
}

.datasearch > div {
  width: 100%;
}

.searchContainer .shopping-cart {
  flex-grow: 0.2;
}

.searchContainer .icon {
  cursor: pointer;
  margin-left: 1rem;
  font-size: 1rem;
  color: #999;
}

.searchContainer .icon.active {
  color: #0b6aff;
}

.popularItem {
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
  padding: 5px 10px;
  justify-content: space-around;
  align-items: center;
}

.popularItem:hover {
  background-color: rgb(238, 238, 238);
}

.datasearch .searchbox {
  min-width: 400px;
  padding: 5px 16px 5px 16px;
}

.suggestionlist {
  color: #424242;
  width: 90%;
  margin-left: 5%;
}

.inverse-button {
  background: transparent;
  border: '1px solid #1A059C';
  color: #1a059c;
}

.rheostat-progress {
  background-color: blue !important;
}

.results {
  position: relative;
  overflow: hidden;
}

.result-stats {
  color: grey;
  padding-left: 10px;
}

.result-data a.active {
  background: #e62f23;
  color: white;
}

.list {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: white;
  z-index: 10;
}

.list.open {
  height: 400px;
}

li.active * {
  color: #e62f23 !important;
}

.rt-resizable-header-content {
  text-align: left;
  padding: 4px;
}

.rt-td {
  padding: 4px;
}

.filter-floating-icon {
  display: none !important;
}

.selected-filters {
  display: none;
  position: relative;
  margin-top: 60px;
}

.hide {
  display: none !important;
}

.sm-phone {
  display: none;
}

.hide-sm {
  display: block;
}

.hide-md {
  display: block;
}

.hide-overflow {
  overflow: hidden;
}

.suggestion-color {
  color: #000;
}

.flex {
  display: flex;
}

.active-icon {
  color: #0b6aff !important;
}

.layout {
  display: flex;
  flex: 1;
}

.right-content {
  width: 100%;
  margin-top: 155px;
  overflow: auto;
  transition: all ease 0.1s;
  background: #fff;
  max-height: calc(100vh - 175px);
}

.map-container {
  max-height: calc(100vh - 215px);
  height: calc(100vh - 215px);
  margin-top: 215px;
}

.render-map {
  height: calc(100vh - 280px);
}

.popover-map {
  width: 250px;
  color: #424242;
  line-height: 1.2rem;
  padding: 12px 0 12px 10px;
}

.custom-filter-wrapper {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  max-height: 250px;
  overflow-y: auto;
}

.color-item {
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.categories-item {
  cursor: pointer;
  height: 60px;
  width: 60px;
  margin-right: 5px;
  margin-bottom: 15px;
  border-radius: 2px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.categories-item-inner {
  height: 50px;
  width: 50px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.active-color-item {
  border: 1px solid;
  border-radius: 2px;
  padding: 2px;
}

.item-inner {
  height: 100%;
  width: 100%;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-container {
  position: fixed;
  top: 120px;
  right: 0;
  max-height: 450px;
  overflow-y: scroll;
  z-index: 2;
  background: #efefef;
  width: 300px;
  color: #333;
  box-shadow: 1px 1px 10px 3px rgba(13, 13, 13, 0.2);
  padding: 10px 15px;
}

.cart-item {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

.cart-item h4 {
  margin: 10px 0;
  margin-top: 0;
}

.cart-item h6 {
  margin: 5px 0;
}

.cart-item .cart-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cart-container .total-price {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.map-filter {
  top: 154px;
  display: flex;
  right: 0;
  transition: all ease 0.1s;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 0;
  padding: 5px;
  height: 60px;
  background: #eee;
}

.map-searchbox {
  flex-grow: 1;
  margin: auto 5px;
}

.home-datasearch li {
  padding: 0 !important;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.search-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.search-links .link-items {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  height: 30px;
}

.search-links .link-items > span {
  padding: 5px 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.search-links .link-items span .text {
  color: rgba(0, 0, 0, 0.55);
  padding-left: 10px;
}

.search-links .link-items span.active .text {
  color: rgba(0, 0, 0, 0.65);
  color: rgba(230, 47, 35, 0.6);
}

.search-links .filter {
  margin-right: 5px;
}

.search-links .filter button {
  min-height: 0;
  margin-top: 8px;
}

.input-close-icon {
  position: absolute;
  right: 55px;
  z-index: 1;
  font-size: 1em !important;
  color: black;
  cursor: pointer;
}

.log-items-wrapper {
  width: 325px;
}

.logo-popup-item {
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #8c8c8c;
  height: 100px;
  width: 100px;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid transparent;
}

.logo-popup-item:hover {
  background: #f5f5f5;
  border: 1px solid #e8e8e8;
}

.courier-img {
  width: 100%;
}

.modal-close-icon {
  float: right;
  cursor: pointer;
  margin: 10px 10px 0;
  padding: 0;
  border-radius: 50%;
  background: white;
  transition: background 0.2s ease;
}

.modal-close-icon:hover {
  background: #e8e8e8;
}

.logo-popup-item svg {
  fill: currentColor;
  height: 30px;
  width: 30px;
}

.logo-popup-item .label {
  margin-top: 10px;
  text-align: center;
  font-size: 13px;
}

.image-upload > input {
  display: none;
}

.selected-filter {
  margin-top: 10px !important;
}

.selected-filter > a {
  background-color: #efefef;
}

.result-stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 0 10px;
  width: 100%;
}

.show-sm {
  visibility: hidden;
  opacity: 0;
  display: none;
}

.facet-popover .ant-popover-content {
  width: 50vw;
}
