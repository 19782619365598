.button-container{
    padding: 20px 0 10px;
}

.step-button, .active-step-button{
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    box-shadow: 0 8px 16px -10px #8c8c8c !important;
    transition: all ease .2s;
}

.step-button img, .active-step-button img{
    width: 120px;
    margin-bottom: 10px;
}

.active-step-button{
    pointer-events: none;
    color: #fafafa;
    border-color: transparent;
    box-shadow: 0 2px 2px #d9d9d9;
}

.step-button:hover{
    border-color: #3f51b5;
    color: #3f51b5;
    box-shadow: 0 6px 8px -6px #bfbfbf !important;
}