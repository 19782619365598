.loader-container {
	text-align: center;
	height: 100vh;
	position: relative;
	background: #eaeaea;
}

.loader {
	position: absolute;
	top: 50%;
	left: 50%;
}

.product-container {
	margin-top: 60px;
	background: #eaeaea;
	width: 100%;
	padding: 20px 50px;
	min-height: calc(100vh - 60px);
}

.flex-column {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 5px;
}

.header {
	display: grid;
	grid-template-columns: 0.5fr auto;
	margin-bottom: 1em;
}

.header-column {
	display: flex;
	flex-wrap: wrap;
}

.image-column {
	text-align: center;
}

.image-column img {
	max-height: 100%;
	max-width: 100%;
}

.header-column > div {
	width: 33%;
	box-sizing: border-box;
	padding: 10px 25px;
}

.header h3 {
	color: #8c8c8c;
	font-weight: 600;
	text-transform: uppercase;
	margin: 0.5em 0 1.5em;
}

.header p {
	color: #000000;
	font-weight: 400;
	margin: 0.5em 0;
}

.flex-column.details {
	padding: 20px 0;
	margin-top: 15px;
	border-bottom: 2px solid #d9d9d9;
}

.flex-column.details:last-child {
	border: 0;
}

.flex-column.details > div {
	box-sizing: border-box;
	padding: 5px 40px;
	width: 27%;
	border-right: 2px solid #d9d9d9;
}

.flex-column.details > div:first-child {
	width: 23%;
}

.flex-column.details > div:last-child {
	border: 0;
	width: 23%;
}

.flex-column.details.center {
	justify-content: center;
}

.flex-column.details.center > div {
	width: 30%;
}

.details img {
	width: 35px;
}

.details h3 {
	color: #8c8c8c;
	font-weight: 500;
	margin: 5px 0;
}

.details p {
	color: #000000;
	font-weight: 500;
	margin: 1.5em 0 0px;
}

.margin-top-0 {
	margin-top: 0 !important;
}

/* Responsive Styles  */

@media (max-width: 1024px) {
	.product-container {
		padding: 30px;
	}

	.header {
		grid-template-columns: 0.8fr auto;
		grid-gap: 10px;
	}

	.header-column > div {
		width: 33%;
		padding: 10px 0 10px 25px;
	}

	.flex-column.details.center > div {
		width: 40%;
	}

	.flex-column.details > div {
		padding: 5px 20px;
	}
}

@media (max-width: 768px) {
	.product-container {
		padding: 20px;
		margin-top: 0;
	}

	.header {
		grid-template-columns: 0.5fr 1fr;
	}

	.header-column > div {
		width: 50%;
	}

	.flex-column.details {
		padding: 0;
		justify-content: center;
		border-bottom: 0;
		margin: 0;
	}

	.flex-column.details > div {
		padding: 20px 40px;
		margin-bottom: 20px;
		border-bottom: 1px solid #d9d9d9;
		width: calc(50% - 20px);
	}

	.flex-column.details > div:nth-child(odd) {
		border-right: 0px solid #d9d9d9;
	}

	.flex-column.details > div:nth-child(even) {
		border-right: 0;
	}

	.flex-column.details > div:first-child {
		width: calc(50% - 20px);
		border-bottom: 1px solid #d9d9d9;
	}

	.flex-column.details > div:last-child {
		border-right: 0;
		border-bottom: 1px solid #d9d9d9;
		width: calc(50% - 20px);
	}
}

@media (max-width: 560px) {
	.flex-column.details > div {
		padding: 20px;
	}
}

@media (max-width: 425px) {
	.product-container {
		padding: 20px 0;
	}

	.header {
		grid-template-columns: 1fr 1fr;
		padding: 10px;
	}

	.header-column > div {
		width: 100%;
		padding: 20px 0 10px 25px;
	}

	.flex-column.details > div {
		width: 50%;
	}

	.flex-column.details > div:first-child {
		width: 50%;
	}

	.flex-column.details > div:last-child {
		width: 50%;
	}
}
