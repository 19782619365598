.tabs-container {
  min-height: calc(100vh - 50px);
  width: 100%;
  background: #fff;
  color: #000 !important;
}

.tab-list {
  display: flex;
  overflow-x: auto;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 5px;
}

.tab-list-item {
  padding: 15px 20px;
  cursor: pointer;
}

.active-tab-item {
  border-bottom: 2px solid #0B6AFF;
}

.dataContainer {
  padding: 0px 20px;
  margin-bottom: 50px;
}

.tab-searchbox .searchinput {
  border-radius: 3px;
  padding: 12px;
  height: 30px;
  font-size: 15px;
}

.circularProgress {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 350px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-size {
  display: flex;
  flex-direction: row-reverse;
}

.tabular-button-container{
  display: flex;
  justify-content: space-around;
  align-items: center;
}