.display {
	display: flex;
	margin-top: 60px;
	font-weight: light;
}

.leftSidebar {
	width: 260px;
	overflow-y: auto;
	padding: 15px 20px;
	left: 0;
	right: 0;
	margin-top: 60px;
	height: 90vh;
	transition: all 0.1s ease;
}

.leftSidebar.leftSidebarClose {
	width: 0px;
	z-index: 0;
	padding: 0;
}

.leftSidebar.leftSidebarOpen {
	width: 260px;
	padding: 0;
}

.facets-sidebar.leftSidebarClose {
	width: 100%;
}

.collapse-button {
	font-size: 20px;
	float: right;
	margin: 60px 10px 0px;
	cursor: pointer;
}

.leftSidebar > div {
	margin: 20px 0 32px 0;
}

.mainBar {
	width: calc(100% - 260px);
	position: relative;
	background-color: #fefefe;
	border-left: 1px solid #f0f0f0;
}

.reactive-map {
	height: calc(100vh - 260px) !important;
}
.map-label,
.map-checkbox {
	border-color: #0b6aff;
}
.map-label:hover {
	color: #000 !important;
}

.result-data .result-item {
	background: #ffffff;
	color: #424242;
}

.result-data .result-item:hover {
	cursor: pointer;
	background: #ffffff;
	color: #424242;
}

.result-data .result-item-title {
	color: #424242;
}

.result-data .result-image {
	background-size: cover;
	max-height: 200px;
}

.result-data .no-results {
	color: #424242;
	margin: 20%;
	text-align: center;
	height: 100vh;
}

li.active {
	color: #0b6aff;
}

.result-data .result-image {
	background-size: cover;
	max-height: 200px;
}

.result-author {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
