.order-details-button {
	color: #ffffff;
	background-color: rgb(211, 69, 90);
	margin-top: 5px;
}

.button-container {
	display: flex;
	justify-content: space-between;
}

.track-button {
	margin-right: 10px;
}

.order-footer {
	margin-right: 15vw;
}
