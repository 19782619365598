body {
	margin: 0;
	padding: 0;
	font-family: 'Open Sans', sans-serif;
}

.flex-wrapper {
	width: 60%;
	margin: 50px auto;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: wrap;
}

.flex-wrapper .box {
	width: 120px;
	height: 120px;
	cursor: pointer;
	text-align: center;
	padding-top: 16px;
}

.flex-wrapper .box .icon {
	width: 60px;
	height: 60px;
}

.cart-info .ant-descriptions-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.cart-info .ant-descriptions-row > td {
	padding: 0 !important;
}

.cart-info .ant-descriptions-title {
	margin-bottom: 5px;
	font-size: 15px;
}

@media (max-width: 768px) {
	.flex-wrapper {
		width: 100%;
	}

	.flex-wrapper .box {
		margin-top: 15px;
	}

	.flex-wrapper .box p {
		text-align: center;
	}
}

@media (max-width: 500px) {
	.flex-wrapper {
		width: 70%;
	}
}

@media (max-width: 380px) {
	.flex-wrapper {
		width: 80%;
	}

	.flex-wrapper .box {
		margin-top: 5px;
	}
}

.custom-button {
	border: 0;
	outline: 0;
	padding: 10px;
	font-size: 13px;
	cursor: pointer;
}

.custom-button.link {
	background: rgba(63, 81, 181, 0.08);
	color: #3f51b5;
	border: 1px solid #3f51b5;
	margin: 2px;
	border-radius: 2px;
}

.custom-button.link.disabled {
	background: #dfdfdf;
	color: #333;
}

.ant-popover-inner-content {
	overflow-y: scroll;
}

.ant-popover {
	width: 25%;
	right: 0;
	left: auto;
}

.ant-descriptions .delete_btn {
	opacity: 0;
	margin-left: auto;
	transition: all ease 0.2s;
	transform: scale(0);
}

.ant-descriptions:hover .delete_btn {
	opacity: 1;
	transform: scale(1);
}

.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 60px;
  font-size: 15px;
  letter-spacing: 0.05rem;
  justify-content: space-between;
}
.voice-search {
  position: absolute;
  right: 65px;
  top: -1px;
}

.voice-search.active {
  right: 49px;
}

.datasearch.home-datasearch > div > div > div:last-child {
  top: calc(50% - 11px);
}

.upload-icon {
  position: absolute;
  right: 80px;
}

.facets-sidebar-wrapper {
  width: 80px;
}

.sidebar-wrapper {
  width: 260px;
  transition: all ease 0.1s;
}

.sidebar-wrapper .ant-collapse {
  border-radius: 0;
}

.sidebar-wrapper .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 !important;
}

.sidebar-wrapper .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.sidebar-wrapper .ant-collapse-content > .ant-collapse-content-box h2 {
  padding: 0;
  font-size: 14px;
  padding-top: 8px;
  color: #1f1f1f;
}

.sidebar-wrapper .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-weight: 500;
  font-size: 15px;
  color: #141414;
}

.sidebar-wrapper
  .ant-collapse
  > .ant-collapse-item-active
  > .ant-collapse-header
  > div {
  color: #e62619;
}

.sidebar-wrapper .admin-btn {
  position: fixed;
  bottom: 0px;
  margin: 0;
  z-index: 2;
}

.sidebar-wrapper .ant-list-split .ant-list-item {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  color: #262626;
  justify-content: flex-start;
}

.sidebar-wrapper .ant-list-split .ant-list-item > span {
  font-size: 16px;
  margin-right: 5px;
}

.sidebar-wrapper .ant-list-split .ant-list-item:hover {
  background: #f5f5f5;
}

.sidebar-wrapper .admin-btn button {
  cursor: pointer;
  width: 260px;
  background: #e62f23;
  color: white;
  border-radius: 0;
}

.sidebar-wrapper .ant-collapse .filter {
  padding: 0 16px 16px;
}

.sidebar-wrapper .ant-collapse .filter > ul {
  max-height: 160px;
}

.logo {
  color: #fff;
  text-decoration: none;
}

.right-menu {
  margin-right: 16px;
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
}

.right-menu > * {
  margin-right: 5px;
}

.datasearch svg.search-icon {
  margin-left: 2px;
  margin-right: 8px;
}

.searchContainer {
  top: 60px; /* Navbar Height is 60px*/
  display: flex;
  right: 0;
  flex-wrap: wrap;
  transition: all ease 0.1s;
  position: fixed;
  align-items: center;
  flex-direction: row;
  padding: 10px 15px;
  width: 100%;
  z-index: 1;
  background: white;
  border: 1px solid #ccc;
}

.searchContainer .content-icons {
  display: flex;
  justify-content: space-around;
}

.searchContainer .search-link-icon {
  display: none;
}

.search-link-icon img {
  width: 16px;
  height: 16px;
}

.searchContainer .search-icons {
  display: none;
}

.searchContainer .ant-select-selection {
  border-radius: 3px 0 0 3px;
  border-right: 0;
}

.searchContainer .ant-select-lg .ant-select-selection--single {
  height: 42px;
}

.searchContainer .ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  box-shadow: none;
  border-color: #d9d9d9;
}

.searchContainer .ant-select-selection:hover {
  border-color: #d9d9d9;
}

.searchContainer .list-options .ant-select-selection {
  border-radius: 4px;
  border-right: 1px solid #d9d9d9;
  width: 120px;
}

.datasearch {
  display: flex !important;
  flex-grow: 1;
}

.datasearch > div {
  width: 100%;
}

.searchContainer .shopping-cart {
  flex-grow: 0.2;
}

.searchContainer .icon {
  cursor: pointer;
  margin-left: 1rem;
  font-size: 1rem;
  color: #999;
}

.searchContainer .icon.active {
  color: #0b6aff;
}

.popularItem {
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
  padding: 5px 10px;
  justify-content: space-around;
  align-items: center;
}

.popularItem:hover {
  background-color: rgb(238, 238, 238);
}

.datasearch .searchbox {
  min-width: 400px;
  padding: 5px 16px 5px 16px;
}

.suggestionlist {
  color: #424242;
  width: 90%;
  margin-left: 5%;
}

.inverse-button {
  background: transparent;
  border: '1px solid #1A059C';
  color: #1a059c;
}

.rheostat-progress {
  background-color: blue !important;
}

.results {
  position: relative;
  overflow: hidden;
}

.result-stats {
  color: grey;
  padding-left: 10px;
}

.result-data a.active {
  background: #e62f23;
  color: white;
}

.list {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: white;
  z-index: 10;
}

.list.open {
  height: 400px;
}

li.active * {
  color: #e62f23 !important;
}

.rt-resizable-header-content {
  text-align: left;
  padding: 4px;
}

.rt-td {
  padding: 4px;
}

.filter-floating-icon {
  display: none !important;
}

.selected-filters {
  display: none;
  position: relative;
  margin-top: 60px;
}

.hide {
  display: none !important;
}

.sm-phone {
  display: none;
}

.hide-sm {
  display: block;
}

.hide-md {
  display: block;
}

.hide-overflow {
  overflow: hidden;
}

.suggestion-color {
  color: #000;
}

.flex {
  display: flex;
}

.active-icon {
  color: #0b6aff !important;
}

.layout {
  display: flex;
  flex: 1 1;
}

.right-content {
  width: 100%;
  margin-top: 155px;
  overflow: auto;
  transition: all ease 0.1s;
  background: #fff;
  max-height: calc(100vh - 175px);
}

.map-container {
  max-height: calc(100vh - 215px);
  height: calc(100vh - 215px);
  margin-top: 215px;
}

.render-map {
  height: calc(100vh - 280px);
}

.popover-map {
  width: 250px;
  color: #424242;
  line-height: 1.2rem;
  padding: 12px 0 12px 10px;
}

.custom-filter-wrapper {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  max-height: 250px;
  overflow-y: auto;
}

.color-item {
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.categories-item {
  cursor: pointer;
  height: 60px;
  width: 60px;
  margin-right: 5px;
  margin-bottom: 15px;
  border-radius: 2px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.categories-item-inner {
  height: 50px;
  width: 50px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.active-color-item {
  border: 1px solid;
  border-radius: 2px;
  padding: 2px;
}

.item-inner {
  height: 100%;
  width: 100%;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-container {
  position: fixed;
  top: 120px;
  right: 0;
  max-height: 450px;
  overflow-y: scroll;
  z-index: 2;
  background: #efefef;
  width: 300px;
  color: #333;
  box-shadow: 1px 1px 10px 3px rgba(13, 13, 13, 0.2);
  padding: 10px 15px;
}

.cart-item {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

.cart-item h4 {
  margin: 10px 0;
  margin-top: 0;
}

.cart-item h6 {
  margin: 5px 0;
}

.cart-item .cart-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cart-container .total-price {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.map-filter {
  top: 154px;
  display: flex;
  right: 0;
  transition: all ease 0.1s;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 0;
  padding: 5px;
  height: 60px;
  background: #eee;
}

.map-searchbox {
  flex-grow: 1;
  margin: auto 5px;
}

.home-datasearch li {
  padding: 0 !important;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.search-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.search-links .link-items {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  height: 30px;
}

.search-links .link-items > span {
  padding: 5px 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.search-links .link-items span .text {
  color: rgba(0, 0, 0, 0.55);
  padding-left: 10px;
}

.search-links .link-items span.active .text {
  color: rgba(0, 0, 0, 0.65);
  color: rgba(230, 47, 35, 0.6);
}

.search-links .filter {
  margin-right: 5px;
}

.search-links .filter button {
  min-height: 0;
  margin-top: 8px;
}

.input-close-icon {
  position: absolute;
  right: 55px;
  z-index: 1;
  font-size: 1em !important;
  color: black;
  cursor: pointer;
}

.log-items-wrapper {
  width: 325px;
}

.logo-popup-item {
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #8c8c8c;
  height: 100px;
  width: 100px;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid transparent;
}

.logo-popup-item:hover {
  background: #f5f5f5;
  border: 1px solid #e8e8e8;
}

.courier-img {
  width: 100%;
}

.modal-close-icon {
  float: right;
  cursor: pointer;
  margin: 10px 10px 0;
  padding: 0;
  border-radius: 50%;
  background: white;
  transition: background 0.2s ease;
}

.modal-close-icon:hover {
  background: #e8e8e8;
}

.logo-popup-item svg {
  fill: currentColor;
  height: 30px;
  width: 30px;
}

.logo-popup-item .label {
  margin-top: 10px;
  text-align: center;
  font-size: 13px;
}

.image-upload > input {
  display: none;
}

.selected-filter {
  margin-top: 10px !important;
}

.selected-filter > a {
  background-color: #efefef;
}

.result-stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
  padding: 0 10px;
  width: 100%;
}

.show-sm {
  visibility: hidden;
  opacity: 0;
  display: none;
}

.facet-popover .ant-popover-content {
  width: 50vw;
}

/*
##Device = Desktops
##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
	.list {
		height: 60px;
	}
}

/*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
	.list {
		height: 60px;
	}
}

@media (min-width: 768px) and (max-width: 1170px) {
	.home-page-search ::-webkit-input-placeholder {
		z-index: -1;
		color: #d8d8d8;
	}

	.home-page-search ::-ms-input-placeholder {
		z-index: -1;
	}

	.home-page-search ::placeholder {
		z-index: -1;
	}
}

/*
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
	.datasearch .searchbox {
		min-width: 200px;
	}

	.hide-md {
		display: none !important;
	}

	.mainBar {
		width: 100% !important;
	}
}

/*
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
	.datasearch .searchbox {
		min-width: auto;
		width: 100%;
	}

	.searchContainer {
		top: 95px;
	}

	.right-content {
		margin-top: 190px;
	}

	.collapse-button {
		margin-top: 90px !important;
	}

	.navbar {
		height: auto;
		padding: 20px;
		align-items: center;
	}

	.mainBar {
		width: 100% !important;
	}

	.tabs-container {
		margin-top: 5px;
	}

	.log-items-wrapper {
		width: 90%;
	}
}

/*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 767px) {
	.right-menu {
		width: 100%;
	}

	.bom-container {
		margin-top: 0;
	}

	.ant-popover {
		width: auto;
	}

	.upload-icon {
		right: 75px;
	}

	.voice-search {
		right: 60px;
		top: -2px;
	}

	.voice-search.active {
		right: 44px;
	}

	.sidebar-wrapper .admin-btn {
		width: 100%;
	}

	.sidebar-wrapper .admin-btn button {
		width: 100%;
	}

	.searchContainer .ant-select-selection {
		border-right: 1px solid #d9d9d9;
		border-radius: 3px;
	}

	.show-sm {
		visibility: visible;
		opacity: 1;
		display: block;
	}

	.cart-info .ant-descriptions-row {
		display: inline-block;
	}

	.hide-sm {
		display: none !important;
	}

	.mainBar {
		width: 100% !important;
	}

	.display {
		margin-top: auto !important;
	}

	.navbar {
		/* width: auto;
		height: auto;
		padding: 20px;
		align-items: flex-start; */
	}

	.logo {
		margin-left: 0;
	}

	.datasearch {
		margin-bottom: 10px;
		flex-grow: 1;
	}

	.leftSidebar {
		position: fixed;
		z-index: 100;
		width: 100% !important;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100vh !important;
		margin-top: 0 !important;
	}

	.navbar {
		position: relative;
		/* flex-direction: column; */
	}

	.navbar > * {
		/* padding-top: 10px; */
	}

	.filter-floating-icon {
		position: fixed !important;
		display: inline-block !important;
		bottom: 10px !important;
		left: 20px;
		z-index: 101;
	}

	.selected-filters {
		display: inline;
	}

	.datasearch .searchbox {
		min-width: auto;
		width: 100%;
		padding-right: 15px;
	}

	.sidebar-wrapper {
		width: auto;
	}

	.home-search-container {
		width: 90%;
	}

	.searchContainer {
		left: 0;
		width: 100% !important;
		flex-direction: column;
	}

	.searchContainer > div {
		width: 100%;
	}

	.datasearch > div > div > div {
		z-index: 0;
	}

	.searchContainer .content-icons {
		flex-grow: 1;
	}

	.searchContainer > div:first-child {
		margin-bottom: 10px;
	}

	.searchContainer .search-link-icon {
		display: block;
	}

	.search-links {
		display: none !important;
	}

	.map-filter {
		left: 0;
		width: 100% !important;
		top: 142px;
		margin-top: 10px;
		display: none;
	}
	.cart-container {
		top: 150px;
	}
	.map-searchbox {
		padding-bottom: 2px;
	}
	.reactive-map {
		top: 40px;
	}

	.homeContent {
		height: auto;
		margin-top: 100px;
		margin-left: 5px;
	}

	.navbar--home {
		flex-direction: row;
	}

	.sm-phone {
		display: block;
	}

	.title-wrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.list > header {
		position: relative;
	}

	.list > header > button {
		position: fixed !important;
		bottom: 10px !important;
		top: unset !important;
		z-index: 101;
	}

	.detail-item {
		font-size: 13px;
	}

	.detail-header {
		font-size: 18px !important;
	}

	.right-content {
		margin-top: 0px !important;
	}

	.tabs-container {
		margin-top: 0 !important;
	}

	.render-map {
		height: calc(100vh - 230px);
	}

	.map-container {
		height: calc(100vh - 145px);
		margin-top: 54px !important;
		max-height: calc(100vh - 120px);
	}

	.results {
		height: auto;
	}

	.collapse-button {
		display: none;
	}

	.input-close-icon {
		right: 55px !important;
		z-index: 1;
	}

	.circularProgress {
		left: 0px !important;
	}

	.log-items-wrapper {
		margin-left: 10px;
		margin-right: 10px;
	}
}

@media (max-width: 348px) {
	.right-content.map-container {
		margin-top: 53px !important;
	}

	.right-content {
		margin-top: 38px !important;
	}
}

@media (max-width: 425px) {
	.bom-footer {
		flex-direction: column;
	}

	.footer-button-margin {
		margin-bottom: 10px;
	}

	.searchContainer .shopping-cart {
		flex-grow: 1;
	}

	.log-items-wrapper {
		width: auto;
	}

	.searchContainer .content-icons {
		border-right: 0;
	}

	.popover-map {
		width: 100%;
	}
}

@media (max-width: 620px) {
	.leftSidebar.leftSidebarClose,
	.facets-sidebar-wrapper {
		width: 0px !important;
	}

	.layout {
		margin-top: 160px;
	}

	.data-container {
		padding: 0;
	}

	.result-data .container {
		padding: 0;
	}

	.card-result-item {
		max-width: 130px;
		margin: 10px 5px !important;
	}

	.button-container {
		flex-direction: column;
	}

	.order-footer {
		margin-top: 10px;
	}

	.view-button {
		width: 145px;
	}
}

@media (max-width: 560px) {
	.right-content {
		margin-top: 0px !important;
	}
}

.display {
	display: flex;
	margin-top: 60px;
	font-weight: light;
}

.leftSidebar {
	width: 260px;
	overflow-y: auto;
	padding: 15px 20px;
	left: 0;
	right: 0;
	margin-top: 60px;
	height: 90vh;
	transition: all 0.1s ease;
}

.leftSidebar.leftSidebarClose {
	width: 0px;
	z-index: 0;
	padding: 0;
}

.leftSidebar.leftSidebarOpen {
	width: 260px;
	padding: 0;
}

.facets-sidebar.leftSidebarClose {
	width: 100%;
}

.collapse-button {
	font-size: 20px;
	float: right;
	margin: 60px 10px 0px;
	cursor: pointer;
}

.leftSidebar > div {
	margin: 20px 0 32px 0;
}

.mainBar {
	width: calc(100% - 260px);
	position: relative;
	background-color: #fefefe;
	border-left: 1px solid #f0f0f0;
}

.reactive-map {
	height: calc(100vh - 260px) !important;
}
.map-label,
.map-checkbox {
	border-color: #0b6aff;
}
.map-label:hover {
	color: #000 !important;
}

.result-data .result-item {
	background: #ffffff;
	color: #424242;
}

.result-data .result-item:hover {
	cursor: pointer;
	background: #ffffff;
	color: #424242;
}

.result-data .result-item-title {
	color: #424242;
}

.result-data .result-image {
	background-size: cover;
	max-height: 200px;
}

.result-data .no-results {
	color: #424242;
	margin: 20%;
	text-align: center;
	height: 100vh;
}

li.active {
	color: #0b6aff;
}

.result-data .result-image {
	background-size: cover;
	max-height: 200px;
}

.result-author {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.loader-container {
	text-align: center;
	height: 100vh;
	position: relative;
	background: #eaeaea;
}

.loader {
	position: absolute;
	top: 50%;
	left: 50%;
}

.product-container {
	margin-top: 60px;
	background: #eaeaea;
	width: 100%;
	padding: 20px 50px;
	min-height: calc(100vh - 60px);
}

.flex-column {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 5px;
}

.header {
	display: grid;
	grid-template-columns: 0.5fr auto;
	margin-bottom: 1em;
}

.header-column {
	display: flex;
	flex-wrap: wrap;
}

.image-column {
	text-align: center;
}

.image-column img {
	max-height: 100%;
	max-width: 100%;
}

.header-column > div {
	width: 33%;
	box-sizing: border-box;
	padding: 10px 25px;
}

.header h3 {
	color: #8c8c8c;
	font-weight: 600;
	text-transform: uppercase;
	margin: 0.5em 0 1.5em;
}

.header p {
	color: #000000;
	font-weight: 400;
	margin: 0.5em 0;
}

.flex-column.details {
	padding: 20px 0;
	margin-top: 15px;
	border-bottom: 2px solid #d9d9d9;
}

.flex-column.details:last-child {
	border: 0;
}

.flex-column.details > div {
	box-sizing: border-box;
	padding: 5px 40px;
	width: 27%;
	border-right: 2px solid #d9d9d9;
}

.flex-column.details > div:first-child {
	width: 23%;
}

.flex-column.details > div:last-child {
	border: 0;
	width: 23%;
}

.flex-column.details.center {
	justify-content: center;
}

.flex-column.details.center > div {
	width: 30%;
}

.details img {
	width: 35px;
}

.details h3 {
	color: #8c8c8c;
	font-weight: 500;
	margin: 5px 0;
}

.details p {
	color: #000000;
	font-weight: 500;
	margin: 1.5em 0 0px;
}

.margin-top-0 {
	margin-top: 0 !important;
}

/* Responsive Styles  */

@media (max-width: 1024px) {
	.product-container {
		padding: 30px;
	}

	.header {
		grid-template-columns: 0.8fr auto;
		grid-gap: 10px;
	}

	.header-column > div {
		width: 33%;
		padding: 10px 0 10px 25px;
	}

	.flex-column.details.center > div {
		width: 40%;
	}

	.flex-column.details > div {
		padding: 5px 20px;
	}
}

@media (max-width: 768px) {
	.product-container {
		padding: 20px;
		margin-top: 0;
	}

	.header {
		grid-template-columns: 0.5fr 1fr;
	}

	.header-column > div {
		width: 50%;
	}

	.flex-column.details {
		padding: 0;
		justify-content: center;
		border-bottom: 0;
		margin: 0;
	}

	.flex-column.details > div {
		padding: 20px 40px;
		margin-bottom: 20px;
		border-bottom: 1px solid #d9d9d9;
		width: calc(50% - 20px);
	}

	.flex-column.details > div:nth-child(odd) {
		border-right: 0px solid #d9d9d9;
	}

	.flex-column.details > div:nth-child(even) {
		border-right: 0;
	}

	.flex-column.details > div:first-child {
		width: calc(50% - 20px);
		border-bottom: 1px solid #d9d9d9;
	}

	.flex-column.details > div:last-child {
		border-right: 0;
		border-bottom: 1px solid #d9d9d9;
		width: calc(50% - 20px);
	}
}

@media (max-width: 560px) {
	.flex-column.details > div {
		padding: 20px;
	}
}

@media (max-width: 425px) {
	.product-container {
		padding: 20px 0;
	}

	.header {
		grid-template-columns: 1fr 1fr;
		padding: 10px;
	}

	.header-column > div {
		width: 100%;
		padding: 20px 0 10px 25px;
	}

	.flex-column.details > div {
		width: 50%;
	}

	.flex-column.details > div:first-child {
		width: 50%;
	}

	.flex-column.details > div:last-child {
		width: 50%;
	}
}

.button-container{
    padding: 20px 0 10px;
}

.step-button, .active-step-button{
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    box-shadow: 0 8px 16px -10px #8c8c8c !important;
    transition: all ease .2s;
}

.step-button img, .active-step-button img{
    width: 120px;
    margin-bottom: 10px;
}

.active-step-button{
    pointer-events: none;
    color: #fafafa;
    border-color: transparent;
    box-shadow: 0 2px 2px #d9d9d9;
}

.step-button:hover{
    border-color: #3f51b5;
    color: #3f51b5;
    box-shadow: 0 6px 8px -6px #bfbfbf !important;
}
.bom-container {
	padding: 20px;
	width: 100%;
	margin-top: 80px;
}

tr.drop-over-kit-item td {
	background: #ffd6e7;
}

tr.drop-over-item td {
	background: #efdbff;
}
tr.drop-over-kit td {
	background: #d6e4ff;
}

tr:hover td {
	background: white !important;
}

td:hover td {
	background: white !important;
}

.delete-cart-item {
	transform: scale(0);
	opacity: 0;
	margin-left: 5px;
	transition: all 0.2s ease-in;
}

tr:hover .delete-cart-item {
	display: inline-block;
	transform: scale(1);
	opacity: 1;
}

.product-search {
	margin-bottom: 20px;
}

.product-search input {
	border-radius: 3px;
}

.bom-footer {
	display: flex;
	justify-content: space-between;
}

.checkout-container {
	padding: 20px;
}

.checkout-navbar {
	text-align: center;
	padding: 20px;
	border-bottom: 1px solid #dddddd;
}

.checkout-navbar img {
	width: 200px;
}

.checkout-content {
	display: grid;
	padding: 20px 0 5px;
	grid-template-columns: 2fr 1fr;
}

.checkout-content .info {
	border-right: 1px solid #dddddd;
	padding: 10px;
}

.checkout-content .info .card {
	display: grid;
	box-sizing: content-box;
	padding: 20px 5px;
	border-bottom: 1px solid #dddddd;
	grid-template-columns: 0.5fr 1fr;
}

.checkout-content .info .card:last-child {
	border-bottom: 0px;
}

.checkout-content .info .card .content {
	padding: 5px;
	cursor: inherit;
}

.info .card h2 {
	margin: 0;
	font-size: 1.1rem;
	font-weight: 600;
}

.info .card .content h2 {
	margin: 0;
	font-size: 1rem;
	font-weight: 500;
}

.address-input {
	margin-top: 10px;
	display: grid;
	grid-template-columns: auto;
	grid-gap: 10px;
}

.address {
	font-weight: 600;
	font-size: 1rem;
	margin-top: 10px;
}

.cart-price {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 15px;
}

.cart-price h2 {
	font-weight: 600 !important;
}

.summary {
	padding: 5px 20px;
}

.user-info {
	padding: 15px 0;
}

.user-info .grey {
	color: grey;
	font-weight: 600;
	font-size: 0.8rem;
}

.user-info .avatar {
	display: flex;
	align-items: center;
}

.user-info .avatar h3 {
	margin: 0;
	font-weight: 600;
}

.user-info .avatar .user-name {
	margin-left: 15px;
}

.summary .payment-info {
	display: grid;
	grid-gap: 8px;
	padding: 20px 0;
}

.summary .payment-info div,
.summary .total-payment {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.summary .payment-info div h2 {
	color: #8c8c8c;
	margin: 0;
	font-size: 0.95rem;
	font-weight: 500;
}

.summary .total-payment {
	padding-top: 15px;
	border-top: 1px solid #dddddd;
}

.summary .total-payment h2 {
	color: #262626;
	margin: 0;
	font-size: 1rem;
	font-weight: 600;
}

.autocomplete-dropdown {
	width: 100%;
	overflow: hidden;
}

.autocomplete-dropdown-container > div{
	border: 1px solid #eee;
	border-bottom: 0;
	padding: 5px 10px;
	font-size: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.autocomplete-dropdown-container > div:first-child{
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.autocomplete-dropdown-container > div:last-child{
	border: 1px solid #eee;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

@media (max-width: 1024px) {
	.checkout-content {
		grid-template-columns: 1fr 1fr;
	}
}

@media (max-width: 768px) {
	.checkout-content {
		grid-template-columns: auto;
    }
    
    .checkout-content .summary {
        grid-row:1;
        padding: 10px 10px 20px;
    }

	.checkout-content .info {
		border-right: 0;
		border-top: 1px solid black;
	}
}
.order-details-button {
	color: #ffffff;
	background-color: rgb(211, 69, 90);
	margin-top: 5px;
}

.button-container {
	display: flex;
	justify-content: space-between;
}

.track-button {
	margin-right: 10px;
}

.order-footer {
	margin-right: 15vw;
}

.tabs-container {
  min-height: calc(100vh - 50px);
  width: 100%;
  background: #fff;
  color: #000 !important;
}

.tab-list {
  display: flex;
  overflow-x: auto;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 5px;
}

.tab-list-item {
  padding: 15px 20px;
  cursor: pointer;
}

.active-tab-item {
  border-bottom: 2px solid #0B6AFF;
}

.dataContainer {
  padding: 0px 20px;
  margin-bottom: 50px;
}

.tab-searchbox .searchinput {
  border-radius: 3px;
  padding: 12px;
  height: 30px;
  font-size: 15px;
}

.circularProgress {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 350px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-size {
  display: flex;
  flex-direction: row-reverse;
}

.tabular-button-container{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.container {
  padding: 0 20px;
  color: #333;
}

.card-result {
  display: flex;
  flex-wrap: wrap;
}

.card-result-item {
  margin: 10px;
}

.content {
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.excerpt {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.panel-header {
	display: flex;
	align-items: center;
}

.facet-panel-icon {
	width: 16px;
	height: 16px;
	margin-right: 10px;
}

.facet-panel-icon.small {
	width: 14px;
	height: 14px;
	margin-right: 6px;
}

